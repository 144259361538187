import React, { useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from 'react-i18next';

function Home() {
  const { language } = useLanguage();
  const { t, i18n } = useTranslation('home');

  useEffect(() => {
    i18n.changeLanguage(language.toLowerCase());
  }, [language, i18n]);

  return ( 
    <div className="main">
      <h1>{t('title')}</h1>
      <br/>
      <p>{t('message')}</p>
      <p><a href="https://www.linkedin.com/company/taqtical/?viewAsMember=true">{t('linkedin')}</a></p>
    </div>
  );
}

export default Home;
